import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { RecordViewModuleStore } from '@/store/modules/record-view';
import { chunkArray } from '@/utils';
let BackupObjectRecordViewPage = class BackupObjectRecordViewPage extends Vue {
    constructor() {
        super(...arguments);
        this.recordId = this.$route.params.recordId;
        this.COLUMNS_COUNT = 2;
    }
    get fields() {
        const objectFields = Object.keys(this.backupObjectRecord || {});
        return chunkArray(objectFields, Math.ceil(objectFields.length / this.COLUMNS_COUNT));
    }
    get storageId() {
        return this.backupStorage?.id;
    }
    goBack() {
        this.$router
            .push({
            name: 'backups.backup.objects.object.records',
            query: this.$route.query,
        })
            .catch(() => false);
    }
    showHistory() {
        this.$router
            .push({
            name: 'backups.backup.objects.object.records.record.history',
            query: this.$route.query,
        })
            .catch(() => false);
    }
};
__decorate([
    RecordViewModuleStore.State
], BackupObjectRecordViewPage.prototype, "backupObjectRecord", void 0);
__decorate([
    RecordViewModuleStore.State
], BackupObjectRecordViewPage.prototype, "backupStorage", void 0);
BackupObjectRecordViewPage = __decorate([
    Component({})
], BackupObjectRecordViewPage);
export default BackupObjectRecordViewPage;
